import {
  BookmarkCriteria,
  BookmarkReq,
  BookmarkRes,
  BookmarkResultsPage,
  PinnedVisualReq,
  PinnedVisualRes,
  ReportCategory,
  ReportEmbedRes,
  ReportSummaryResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { ImmerReducer } from "immer-reducer";
import _ from "lodash";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

import { IApiResponse } from "../../models/common/ApiResponseModel";
import { K2AnalyticsManagement } from "./types";

const initialState: K2AnalyticsManagement = {
  loading: false,
  pinToDashboardLoading: false,
  captureBookmarkLoading: false,
  deleteBookmarkLoading: false,
  bookmarksLoading: false,
  allReports: { loading: true, items: [] },
  allBookmarks: {},
  embedReport: {},
  pinnedVisual: null,
  capturedBookmark: null,
  unpinnedVisual: false,
  unpinnedVisualId: null,
  editStatus: null,
  serverError: null,
};

class AnalyticsReducer extends ImmerReducer<K2AnalyticsManagement> {
  pinToDashboardLoading(loading: boolean) {
    this.draftState.pinToDashboardLoading = loading;
  }

  bookmarksLoading(loading: boolean) {
    this.draftState.bookmarksLoading = loading;
  }

  deleteBookmarkLoading(loading: boolean) {
    this.draftState.deleteBookmarkLoading = loading;
  }

  captureBookmarkLoading(loading: boolean) {
    this.draftState.captureBookmarkLoading = loading;
  }

  analyticsLoading(loading: boolean) {
    this.draftState.loading = loading;
  }

  analyticsError(error: ServerError) {
    if (!_.isNull(error)) {
      this.draftState.serverError = error;
    }
  }

  fetchAllReports(_category: ReportCategory[], _reportingPlatform: string) {
    this.draftState.allReports = {
      ...this.state.allReports,
      loading: true,
    };
  }

  setAllReports(
    category: ReportCategory[],
    response: IApiResponse<ReportSummaryResultsPage>,
  ) {
    const reportsPerCateogary = [];
    const items = response.getResultOrDefault().items;

    category.forEach((x) => {
      const reportPerCateogary = items.filter((item) => item.category === x);
      if (reportPerCateogary.length > 0) {
        reportsPerCateogary.push({
          reports: reportPerCateogary.sort((a, b) => a.order - b.order),
          category: x,
        });
      }
    });
    this.draftState.allReports = {
      loading: false,
      items: [...reportsPerCateogary],
    };
    this.analyticsError(response.error);
    this.analyticsLoading(false);
  }

  fetchEmbeddedReport(_reportId: string, _reportingPlatform: string) {
    this.analyticsLoading(true);
  }

  setEmbedReport(response: IApiResponse<ReportEmbedRes>) {
    this.draftState.embedReport = response.getResultOrDefault();
    this.analyticsError(response.error);
    this.analyticsLoading(false);
  }

  pinVisualToDashboard(
    _reportId: string,
    _pinnedVisualRequest: PinnedVisualReq,
    _reportingPlatform: string,
  ) {
    this.pinToDashboardLoading(true);
  }

  setPinnedVisualResponse(response: IApiResponse<PinnedVisualRes>) {
    this.draftState.pinnedVisual = response.getResultOrDefault();
    this.analyticsError(response.error);
    this.pinToDashboardLoading(false);
  }

  fetchAllBookmarks(_criteria: BookmarkCriteria, _reportingPlatform: string) {
    this.bookmarksLoading(true);
  }

  setAllBookmarks(response: IApiResponse<BookmarkResultsPage>) {
    this.draftState.allBookmarks = response.getResultOrDefault();

    this.analyticsError(response.error);
    this.bookmarksLoading(false);
  }

  deleteBookmark(_bookmarkRevisionId: string, _reportingPlatform: string) {
    this.deleteBookmarkLoading(true);
  }

  setDeleteBookmarkResponse(response: IApiResponse<void>) {
    this.analyticsError(response.error);
    this.deleteBookmarkLoading(false);
  }

  captureBookmark(
    _reportId: string,
    _captureBookmarkRequest: BookmarkReq,
    _reportingPlatform: string,
    _bookmarkRevisionId?: string,
  ) {
    this.captureBookmarkLoading(true);
  }

  setCaptureBookmarkResponse(
    response: IApiResponse<BookmarkRes>,
    bookmarkReq?: BookmarkReq,
  ) {
    if (!_.isNil(bookmarkReq)) {
      const newBookmarkReqState = {
        ...this.state.capturedBookmark,
        ...bookmarkReq,
      };
      this.draftState.capturedBookmark = newBookmarkReqState;
    } else {
      this.draftState.capturedBookmark = response.getResultOrDefault();
    }

    this.analyticsError(response.error);
    this.captureBookmarkLoading(false);
  }

  unpinVisualFromDashboard(visualId: string, _reportingPlatform: string) {
    this.draftState.unpinnedVisualId = visualId;
  }

  setUnPinnedVisualResponse(response: IApiResponse<void>) {
    this.draftState.unpinnedVisual = true;
    this.analyticsError(response.error);
  }

  resetUnPinnedVisual() {
    this.draftState.unpinnedVisual = false;
    this.draftState.unpinnedVisualId = null;
  }

  clearReportsAndVisuals() {
    this.draftState.allReports = { loading: true, items: [] };
    this.draftState.embedReport = {};
    this.draftState.pinnedVisual = null;
    this.draftState.capturedBookmark = null;
    this.draftState.unpinnedVisual = false;
  }

  clearServerError() {
    this.draftState.serverError = null;
  }
}

export { initialState, AnalyticsReducer };
