import { useDispatch, useSelector } from "react-redux";

import LookupsModel, {
  MergedLookupType,
} from "appRedux/models/lookups/LookupsModel";
import {
  fetchLookups,
  setLookupsLoading,
} from "appRedux/modules/lookups/actions";
import { K2Lookup, NamedLookup } from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";

// This hook can be used to dynamically access a single lookup from anywhere in the app.
function useLookup<T extends K2Lookup<any> = K2Lookup<NamedLookup>>(
  lookupName: MergedLookupType,
): [T] {
  const data = useSelector((state: IApplicationState) => state.lookups);
  const dispatch = useDispatch();

  if (!lookupName || !data[lookupName])
    return [{ items: [], loading: false, token: "" }] as [T];

  async function load() {
    dispatch(setLookupsLoading(true, [lookupName]));

    const lookupsToFetch = LookupsModel.determineLookupsToFetch(
      [lookupName],
      data,
    );

    dispatch(fetchLookups(lookupsToFetch));
  }

  const now = new Date();
  const lookupExpired = now >= new Date(data[lookupName].expiryTime);
  if (
    (!data[lookupName].items.length || lookupExpired) &&
    !data[lookupName].loading &&
    !data[lookupName].token
  ) {
    load();
  }

  return [data[lookupName]];
}

export default useLookup;
