import { useCallback, useState } from "react";

import {
  ReportCriteria,
  ReportRes,
  ReportResultsPage,
} from "@n3oltd/karakoram.reporting.sdk.reports";

import { _reportingClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export default function useFindReports(
  k2Intl: InjectedK2Intl,
): {
  reports: ReportResultsPage;
  loading: boolean;
  findReports: (reportCriteria: ReportCriteria) => void;
  updateSpecificReport: (sepecificReport: ReportRes) => void;
} {
  const [reports, setReports] = useState<ReportResultsPage>({
    items: [],
    continuationToken: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const findReports = useCallback(
    async (criteria: ReportCriteria) => {
      setLoading(true);
      const response: IApiResponse<ReportResultsPage> = await K2RestService.toResponse(
        _reportingClient.findReports({
          ...criteria,
        }),
      );
      if (!response.error) {
        if (criteria.continuationToken) {
          setReports((reports) => ({
            items: [...reports.items, ...response.getResultOrDefault().items],
            continuationToken: response.getResultOrDefault().continuationToken,
          }));
        } else {
          setReports(response.getResultOrDefault());
        }
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
      setLoading(false);
    },
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
    [],
  );

  const updateSpecificReport = (sepecificReport: ReportRes) => {
    setReports((report) => ({
      items: report.items.map((item) => {
        if (item.id === sepecificReport.id) {
          return sepecificReport;
        }
        return item;
      }),
      continuationToken: report.continuationToken,
    }));
  };

  return {
    reports,
    loading,
    findReports,
    updateSpecificReport
  };
}
