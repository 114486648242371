import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { useGetIsProdEnvironment } from "hooks";

interface GoodDataDashboardProps {
  iframeSource: string;
}

export interface GoodDataDashboardRef {
  setFilter: (from: string, to: string) => void;
}

const GoodDataDashboard = forwardRef<
  GoodDataDashboardRef,
  GoodDataDashboardProps
>(({ iframeSource }, ref) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const isProdEnv = useGetIsProdEnvironment();

  useImperativeHandle(ref, () => ({
    setFilter: (from, to) => {
      if (iframeRef.current) {
        const message = {
          gdc: {
            product: "dashboard",
            event: {
              name: "setFilterContext",
              data: {
                filters: [
                  {
                    absoluteDateFilter: {
                      from: from,
                      to: to,
                    },
                  },
                ],
              },
            },
          },
        };
        iframeRef.current.contentWindow?.postMessage(
          message,
          "https://n3o.cloud.gooddata.com",
        );
      }
    },
  }));

  return (
    <iframe
      src={iframeSource}
      height="1000vh"
      width="100%"
      title="Gooddata Reporting Dashboard"
      ref={iframeRef}
    ></iframe>
  );
});

export default GoodDataDashboard;
