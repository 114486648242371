import { useCallback, useState } from "react";

import {
  ReportingPeriodCriteria,
  ReportingPeriodSummaryResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.attribution";

import { _attributionClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export default function useFindReportingPeriods(
  k2Intl: InjectedK2Intl,
): {
  reportingPeriods: ReportingPeriodSummaryResultsPage;
  loadingReportingPeriods: boolean;
  findReportingPeriods: (reportCriteria: ReportingPeriodCriteria) => void;
} {
  const [
    reportingPeriods,
    setReportingPeriods,
  ] = useState<ReportingPeriodSummaryResultsPage>({
    items: [],
    continuationToken: "",
  });
  const [
    loadingReportingPeriods,
    setLoadingReportingPeriods,
  ] = useState<boolean>(false);

  const findReportingPeriods = useCallback(
    async (criteria: ReportingPeriodCriteria) => {
      setLoadingReportingPeriods(true);

      const response: IApiResponse<ReportingPeriodSummaryResultsPage> = await K2RestService.toResponse(
        _attributionClient.findReportingPeriods(criteria),
      );
      if (!response.error) {
        if (criteria.continuationToken) {
          setReportingPeriods((reports) => ({
            items: [...reports.items, ...response.getResultOrDefault().items],
            continuationToken: response.getResultOrDefault().continuationToken,
          }));
        } else {
          setReportingPeriods(response.getResultOrDefault());
        }
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
      setLoadingReportingPeriods(false);
    },
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
    [],
  );

  return {
    reportingPeriods,
    loadingReportingPeriods,
    findReportingPeriods,
  };
}
