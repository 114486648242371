import { useEffect, useState } from "react";

import { LookupType as AnalyticsLookups } from "@n3oltd/karakoram.analytics.sdk.lookups/esm";

import {
  NamedLookup,
  ReportingPlatformsLookups,
} from "appRedux/modules/lookups/types";

import useLookup from "./useLookup";

export const ReportingPlatforms = {
  goodData: "goodData",
  powerBi: "powerBI",
};

export function useGetReportingPlatform(platformId: string): NamedLookup {
  const [reportingPlatform, setReportingPlatform] = useState<NamedLookup>({});
  const [reportingPlatforms] = useLookup<ReportingPlatformsLookups>(
    AnalyticsLookups.ReportingPlatforms,
  );

  const { loading, items } = reportingPlatforms;

  useEffect(() => {
    const platform =
      items.find((platform) => platform.id === platformId) ||
      ({} as NamedLookup);

    setReportingPlatform(platform);
  }, [loading, platformId, items]);

  return reportingPlatform;
}
