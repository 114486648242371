import { RestResponse } from "@azure/ms-rest-js";
import {
  BookmarkRes,
  BookmarkResultsPage,
  PinnedVisualRes,
  ReportEmbedRes,
  ReportSummaryResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import _ from "lodash";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import { analyticsActions } from ".";
import { _reportsClient } from "../../models/base/K2RestClients";
import K2Service from "../../models/base/K2RestService";
import { IApiResponse } from "../../models/common/ApiResponseModel";

//workers

function* fetchAllReports(
  action: ReturnType<typeof analyticsActions.fetchAllReports>,
) {
  try {
    const response: IApiResponse<ReportSummaryResultsPage> = yield K2Service.toResponse(
      _reportsClient.findReports(action.payload[1], {
        category: action.payload[0],
      }),
    );
    yield put(analyticsActions.setAllReports(action.payload[0], response));
  } catch (e) {
    yield put(analyticsActions.analyticsLoading(false));
  }
}

function* fetchEmbeddedReport(
  action: ReturnType<typeof analyticsActions.fetchEmbeddedReport>,
) {
  try {
    const response: IApiResponse<ReportEmbedRes> = yield K2Service.toResponse(
      _reportsClient.getReportEmbedById(action.payload[0], action.payload[1]),
    );
    yield put(analyticsActions.setEmbedReport(response));
  } catch (e) {
    yield put(analyticsActions.analyticsLoading(false));
  }
}

function* pinVisualToDashboard(
  action: ReturnType<typeof analyticsActions.pinVisualToDashboard>,
) {
  try {
    const response: IApiResponse<PinnedVisualRes> = yield K2Service.toResponse(
      _reportsClient.createPinnedVisual(
        action.payload?.[0],
        action.payload?.[2],
        action.payload?.[1],
      ),
    );
    yield put(analyticsActions.setPinnedVisualResponse(response));
  } catch (e) {
    yield put(analyticsActions.pinToDashboardLoading(false));
  }
}

function* fetchAllBookmarks(
  action: ReturnType<typeof analyticsActions.fetchAllBookmarks>,
) {
  try {
    const response: IApiResponse<BookmarkResultsPage> = yield K2Service.toResponse(
      _reportsClient.findBookmarks(action.payload[1], action.payload[0]),
    );
    yield put(analyticsActions.setAllBookmarks(response));
  } catch (e) {
    yield put(analyticsActions.bookmarksLoading(false));
  }
}

function* deleteBookmark(
  action: ReturnType<typeof analyticsActions.deleteBookmark>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _reportsClient.deleteBookmark(action.payload[0], action.payload[1]),
    );
    yield put(analyticsActions.setDeleteBookmarkResponse(response));
  } catch (e) {
    yield put(analyticsActions.deleteBookmarkLoading(false));
  }
}

function* captureBookmark(
  action: ReturnType<typeof analyticsActions.captureBookmark>,
) {
  try {
    let response: IApiResponse<BookmarkRes>;

    if (!_.isNil(action.payload?.[3])) {
      response = yield K2Service.toResponse(
        _reportsClient.updateBookmark(
          action.payload?.[3],
          action.payload[2],
          action.payload?.[1],
        ),
      );
    } else {
      response = yield K2Service.toResponse(
        _reportsClient.createBookmark(
          action.payload?.[0],
          action.payload[2],
          action.payload?.[1],
        ),
      );
    }

    yield put(
      analyticsActions.setCaptureBookmarkResponse(
        response,
        !_.isNil(action.payload?.[2]) ? action.payload?.[1] : undefined,
      ),
    );
  } catch (e) {
    yield put(analyticsActions.captureBookmarkLoading(false));
  }
}

function* unpinVisualFromDashboard(
  action: ReturnType<typeof analyticsActions.unpinVisualFromDashboard>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _reportsClient.deletePinnedVisual(action.payload[0], action.payload[1]),
    );
    yield put(analyticsActions.setUnPinnedVisualResponse(response));
  } catch (e) {
    yield put(analyticsActions.pinToDashboardLoading(false));
  }
}

//watcher

export default function* analyticsSaga() {
  yield all[
    (yield takeEvery(analyticsActions.fetchAllReports.type, fetchAllReports),
    yield takeLatest(
      analyticsActions.fetchEmbeddedReport.type,
      fetchEmbeddedReport,
    ),
    yield takeLatest(
      analyticsActions.pinVisualToDashboard.type,
      pinVisualToDashboard,
    ),
    yield takeLatest(
      analyticsActions.fetchAllBookmarks.type,
      fetchAllBookmarks,
    ),
    yield takeLatest(analyticsActions.captureBookmark.type, captureBookmark),
    yield takeLatest(analyticsActions.deleteBookmark.type, deleteBookmark),
    yield takeLatest(
      analyticsActions.unpinVisualFromDashboard.type,
      unpinVisualFromDashboard,
    ))
  ];
}
