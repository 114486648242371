import styled from "styled-components";

import { K2SelectV4 } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

// use this component when we have button next to select
// this will match the button size
const K2SelectV4Extended = styled(K2SelectV4)`
  && {
    .ant-select-selection {
      height: 30px;
    }
    .ant-select-selection-item {
      line-height: 30px !important;
    }
    .ant-select-selector {
      height: 32px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 30px !important;
    }
  }

  &&&&& .ant-select-selection-item {
    line-height: 30px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 30px !important;
  }
`;

export default injectK2Intl(K2SelectV4Extended);
